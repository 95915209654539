import Swiper, {Autoplay, EffectCoverflow, EffectFade, Navigation, Pagination} from 'swiper';
import GLightbox from "glightbox";
import { multipleSelect } from 'multiple-select-vanilla';
import {Map, Marker, NavigationControl, Popup} from 'maplibre-gl';

import UIkit from 'uikit';

// Enable icons
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);


var _paq = window._paq = window._paq || [];
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
(function() {
var u="https://matomo.atw.io/";
_paq.push(['setTrackerUrl', u+'matomo.php']);
_paq.push(['setSiteId', '41']);
var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
})();

document.addEventListener("DOMContentLoaded", function (event) {

    // INIT MENUBUTTON
    const menu_button = document.querySelector('[data-behaviour="toggle-menu"]');
    menu_button.addEventListener('click', () => {
        document.body.classList.toggle('body--show');
    })

    // INIT LIGHTBOX
    // const lightbox = GLightbox({
    //     selector: '[data-gallery="gallery"]',
    //     touchNavigation: true,
    //     loop: true,
    // });
    //
    // // INIT SWIPER
    // const sliders = document.querySelectorAll('.swiper');
    // sliders.forEach(function (slider) {
    //     const autoSwiper = slider.classList.contains('swiper--auto');
    //     const swiper = new Swiper(slider, {
    //         // configure Swiper to use modules
    //         modules: [Pagination, Navigation, Autoplay, EffectFade],
    //         effect: 'slide',
    //         fadeEffect: {
    //             crossFade: true
    //         },
    //         direction: 'vertical',
    //         loop: true,
    //
    //         autoplay: autoSwiper ? {
    //             delay: 5000,
    //         } : false,
    //
    //         // Navigation arrows
    //         navigation: {
    //             nextEl: '.swiper-button-next',
    //             prevEl: '.swiper-button-prev',
    //         },
    //
    //         pagination: {
    //             el: '.swiper-pagination',
    //             type: 'bullets',
    //             clickable: true,
    //         },
    //     });
    // });
    //
    // // FIXED HEADER
    // window.addEventListener('scroll', () => {
    //     if (document.documentElement.scrollTop > 30 || document.body.scrollTop > 30){
    //         document.body.classList.add('menu--fixed');
    //     } else {
    //         document.body.classList.remove('menu--fixed');
    //     }
    // });

    // INIT MULTIPLE SELECT
    const multipleSelects = document.querySelectorAll('[data-behaviour="multiple-select"]');
    multipleSelects.forEach(function (select) {
        multipleSelect(select, {
            placeholder: 'Bitte wählen',
            selectAllText: 'Alle',
            allSelectedText: 'Alle',
            formatCountSelected: function(numSelected, numTotal) {
                return numSelected + ' von ' + numTotal + ' ausgewählt';
            }
        });
    });

    // EMPLOYER EDIT JOB LISTING
    const addJobListing = document.querySelector('[data-behaviour="addJobToEdit"]');
    const jobTemplate = document.querySelector('.form_group--template');
    var newJobID = -1;
    if (addJobListing) {
        addJobListing.addEventListener('click', () => {
            console.log('Add Job Listing');
            const jobsHolder = addJobListing.previousElementSibling.firstElementChild;
            const jobForm = jobTemplate.cloneNode(true);
            jobForm.classList.remove('form_group--template');
            const newJobHTML = jobForm.outerHTML.replaceAll('XX', newJobID);
            const newJob = document.createElement('div');
            newJob.classList.add('fieldgroup-field');
            newJob.innerHTML = newJobHTML;
            newJob.style.display = 'block';
            const multipleSelects = newJob.querySelectorAll('[data-behaviour="multiple-select-lazy"]');
            multipleSelects.forEach(function (select) {
                multipleSelect(select, {
                    placeholder: 'Bitte wählen',
                    selectAllText: 'Alle',
                    allSelectedText: 'Alle',
                    formatCountSelected: function(numSelected, numTotal) {
                        return numSelected + ' von ' + numTotal + ' ausgewählt';
                    }
                });
            });
            jobsHolder.appendChild(newJob);
            newJobID--;
        });
    }

    // JOBS LISTING DAY TOGGLE
    const dayButtons = document.querySelectorAll('[data-behaviour="jobs-change-day"]');
    if(dayButtons.length > 0) {
        dayButtons.forEach(function (button) {
            button.addEventListener('click', () => {
                const day = button.dataset.date;
                const joblists = document.querySelectorAll('[data-behaviour="jobs-change-list"]');
                joblists.forEach(function (joblist) {
                    if (joblist.dataset.date != day) {
                        joblist.classList.remove('list--active');
                    } else {
                        joblist.classList.add('list--active');
                    }
                });
                dayButtons.forEach(function (button) {
                    button.classList.remove('button--active');
                });

                dayButtons.forEach(function (buttonB) {
                    buttonB.classList.remove('link--active');
                });

                button.classList.add('link--active');
            });
        });
    }

    // ## Maps ##
    const maps = document.querySelectorAll('[data-behaviour="map"]');
    if(maps) {
        const api_key = '8d8bf301d9d530613f96f2c42b47551a';
        maps.forEach(function (mapElement) {
            const center = mapElement.getAttribute('data-center');
            const map = new Map({
                container: mapElement,
                style: `https://www.goodmaps.de/map-styles/${api_key}`, // style URL
                center: center ? center.split(',') : [10.2382400606724, 53.67588094894284], // starting position [lng, lat]
                zoom: 11, // starting zoom
                cooperativeGestures: true,
            });
            map.addControl(new NavigationControl());

            const dataElement = mapElement.querySelector('[data-behaviour="map-locations"]');
            const locations = JSON.parse(dataElement.textContent);

            locations.forEach(function (location) {
                let locationLink = "#";
                let description = "";
                const popup = new Popup({offset: 25})

                if(location.Description == null){
                    description = "";
                } else {
                    description = '<p class="map_marker_description">' + location.Description + '</p>';
                }

                popup.setHTML(
                    `<h4 class="map_marker_title">${location.Title}</h4>` + description
                );

                const marker = new Marker()
                    .setLngLat([location.Longitude, location.Latitude])
                    .setPopup(popup)
                    .addTo(map);
            });

            const mapjumpers = document.querySelectorAll('[data-behaviour="map-jumper"]');
            if(mapjumpers){
                mapjumpers.forEach(function (mapjumper) {
                    mapjumper.addEventListener('click', () => {
                        const targetLongitude = mapjumper.getAttribute('data-longitude');
                        const targetLatitude = mapjumper.getAttribute('data-latitude');
                        map.jumpTo({center: [targetLongitude, targetLatitude], zoom: 13});
                    })
                })
            }

            const maplisttoggle = mapElement.parentElement.querySelector('[data-behaviour="map-list-toggle"]');
            if(maplisttoggle) {
                maplisttoggle.addEventListener('click', () => {
                    mapElement.parentNode.classList.toggle('show--list');
                })
            }
        })
    }
});
